// @ToDo: This file and anything relating to KeyVisual "layout4" can be removed after content migration to MediaPlayer
import React from "react";

import { animated, config, useSpringRef, useChain, useSpring } from "@react-spring/web";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Row } from "@/components/grid";
import { BlockRow, FlexRow } from "@/components/grid/extensions";
import { ContentfulNextImage } from "@/components/image";
import { Typography } from "@/components/typography/typography";
import type { WidgetType } from "@/components/widget";
import { Widget } from "@/components/widget";
import { VideoContext } from "@/context/video";
import { useVideoContext } from "@/hooks/video";
import { TypographyVariant } from "@/theme";
import type { KeyVisual } from "@/types/contentful-api";

import {
	ContentfulVideo,
	Wrapper,
	BackgroundVideo,
	getClipPathFromTime,
	VisualContent,
	VisualHeadline,
} from "./components.media-player";

export const VideoVisual: React.FC<{ data: KeyVisual }> = ({ data }) => {
	const {
		featuredImage,
		featuredVideo,
		headline,
		subtitle,
		widgetType,
		widgetTitle,
		externalCta,
	} = data;
	const video = useVideoContext();
	const [done, setDone] = React.useState(false);

	const y2Ref = useSpringRef();
	const yRef = useSpringRef();

	const { y: y2 } = useSpring({
		ref: y2Ref,
		config: { ...config.stiff, clamp: true },
		from: {
			y: 1,
		},
		to: {
			y: 0,
		},
		onRest: () => setDone(true),
	});

	const { y } = useSpring({
		ref: yRef,
		config: { ...config.stiff, clamp: true },
		from: {
			y: 1,
		},
		to: {
			y: 0,
		},
	});

	useChain([y2Ref, yRef], [1, 0]);

	// This is reset on done so that the clipPath isn't being an "overflow hidden"
	const clippedStyle = done
		? {}
		: {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				clipPath: y.to((time: number) => getClipPathFromTime(time)) as any,
		  };

	const innerStyle: React.CSSProperties = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		transform: y2.to((time: number) => `translate3d(0,${time * 10}%, 0)`) as any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		opacity: y2.to((time: number) => 1 - time) as any,
	};

	return (
		<Wrapper style={clippedStyle as React.CSSProperties}>
			{!featuredVideo && (
				<ContentfulNextImage
					priority
					src={featuredImage.url}
					alt={featuredImage.description}
					quality={65}
					layout="fill"
					objectFit="cover"
				/>
			)}
			<BackgroundVideo data-test-id="background-video">
				{featuredVideo && (
					<ContentfulVideo
						ref={video.ref}
						src={featuredVideo.url}
						poster={`${featuredImage.url}?fm=webp&w=1280&fit=fill&q=70`}
						done={done}
					/>
				)}
			</BackgroundVideo>
			<Grid overflow>
				<Row>
					<Column flex l={7}>
						<animated.div style={innerStyle}>
							<VisualContent>
								<FlexRow>
									<Column flex l={7}>
										<VisualHeadline>
											<Typography
												tight
												component="h1"
												variant={TypographyVariant.headlineSerif2XL}
											>
												<BreakLines text={headline} />
											</Typography>
											{subtitle && (
												<Typography>
													<BreakLines text={subtitle} />
												</Typography>
											)}
										</VisualHeadline>
									</Column>
								</FlexRow>
								<BlockRow>
									<Widget
										type={widgetType as WidgetType}
										data={{ widgetTitle, featuredVideo, externalCta }}
									/>
								</BlockRow>
							</VisualContent>
						</animated.div>
					</Column>
				</Row>
			</Grid>
		</Wrapper>
	);
};

export const Layout4: React.FC<{ data: KeyVisual }> = ({ data }) => {
	const ref = React.useRef<HTMLVideoElement>();
	const [playing, setPlaying] = React.useState(false);

	const stop = React.useCallback(() => {
		if (ref.current) {
			setPlaying(false);
			ref.current.pause();
			ref.current.currentTime = 0;
		}
	}, [ref]);

	const pause = React.useCallback(() => {
		if (ref.current) {
			setPlaying(false);
			ref.current.pause();
		}
	}, [ref]);

	const play = React.useCallback(async () => {
		if (ref.current) {
			try {
				await ref.current.play();
				setPlaying(true);
			} catch {
				// Usually play interrupted by user setting or route change
			}
		}
	}, [ref]);

	const context = React.useMemo(
		() => ({
			playing,
			play,
			stop,
			pause,
			ref,
		}),
		[playing, play, stop, pause, ref]
	);
	return (
		<VideoContext.Provider value={context}>
			<VideoVisual data={data} />
		</VideoContext.Provider>
	);
};
