import React from "react";

import { animated, config, useChain, useSpring, useSpringRef } from "@react-spring/web";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Row } from "@/components/grid";
import { FlexRow } from "@/components/grid/extensions";
import { ContentfulNextImage } from "@/components/image";
import { Typography } from "@/components/typography/typography";
import { Widget, WidgetType } from "@/components/widget";
import { VideoContext } from "@/context/video";
import { useVideoContext } from "@/hooks/video";
import { useViewport } from "@/hooks/viewport";
import { TypographyVariant } from "@/theme";
import type { MediaPlayer as MediaPlayerProps } from "@/types/contentful-api";
import { AssetFit, AssetFocus } from "@/types/contentful-images";

import {
	BackgroundVideo,
	ContentfulVideo,
	getClipPathFromTime,
	VisualContent,
	VisualHeadline,
	Wrapper,
} from "./components.media-player";

export const MediaPlayerVisual: React.FC<{ data: MediaPlayerProps }> = ({ data }) => {
	const {
		headline,
		subtitle,
		poster,
		videoButtonText,
		videoPreview,
		videoSd,
		videoHd,
		showMobileVideo,
	} = data;

	const video = useVideoContext();
	const [done, setDone] = React.useState(false);

	const y2Ref = useSpringRef();
	const yRef = useSpringRef();
	const { s, l } = useViewport();
	const { y: y2 } = useSpring({
		ref: y2Ref,
		config: { ...config.stiff, clamp: true },
		from: {
			y: 1,
		},
		to: {
			y: 0,
		},
		onRest: () => setDone(true),
	});
	const { y } = useSpring({
		ref: yRef,
		config: { ...config.stiff, clamp: true },
		from: {
			y: 1,
		},
		to: {
			y: 0,
		},
	});

	useChain([y2Ref, yRef], [1, 0]);

	const clippedStyle = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		clipPath: y.to((time: number) => getClipPathFromTime(time)) as any,
	};

	const innerStyle = {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		transform: y2.to((time: number) => `translate3d(0,${time * 10}%, 0)`) as any,
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		opacity: y2.to((time: number) => 1 - time) as any,
	};

	const widgetData = React.useMemo(
		() => ({
			widgetTitle: videoButtonText,
			featuredImage: poster,
			videoHd,
			videoSd,
		}),
		[videoButtonText, poster, videoHd, videoSd]
	);
	return (
		<Wrapper style={clippedStyle as React.CSSProperties}>
			<BackgroundVideo>
				{videoPreview && ((s && showMobileVideo) || l) ? (
					<ContentfulVideo
						ref={video.ref}
						src={videoPreview.url}
						poster={poster.url}
						done={done}
					/>
				) : (
					<ContentfulNextImage
						priority
						src={poster.url}
						alt={poster.description}
						fit={AssetFit.crop}
						f={AssetFocus.center}
						quality={75}
						height={700}
						layout="fill"
						objectFit="cover"
					/>
				)}
			</BackgroundVideo>
			<Grid overflow>
				<Row>
					<Column flex l={7}>
						<animated.div style={innerStyle}>
							<VisualContent>
								<FlexRow>
									<Column flex l={7}>
										<VisualHeadline>
											<Typography
												tight
												component="h1"
												variant={TypographyVariant.headlineSerif2XL}
											>
												<BreakLines text={headline} />
											</Typography>
											{subtitle && (
												<Typography>
													<BreakLines text={subtitle} />
												</Typography>
											)}
											{videoButtonText && (
												<Widget
													type={WidgetType.videoModal}
													data={widgetData}
												/>
											)}
										</VisualHeadline>
									</Column>
								</FlexRow>
							</VisualContent>
						</animated.div>
					</Column>
				</Row>
			</Grid>
		</Wrapper>
	);
};

export const MediaPlayer: React.FC<{ data: MediaPlayerProps }> = ({ data }) => {
	const ref = React.useRef<HTMLVideoElement>();

	const [playing, setPlaying] = React.useState(false);

	const stop = React.useCallback(() => {
		if (ref.current) {
			setPlaying(false);
			ref.current.pause();
			ref.current.currentTime = 0;
		}
	}, [ref]);

	const pause = React.useCallback(() => {
		if (ref.current) {
			setPlaying(false);
			ref.current.pause();
		}
	}, [ref]);

	const play = React.useCallback(async () => {
		if (ref.current) {
			try {
				await ref.current.play();
				setPlaying(true);
			} catch {
				// Usually play interrupted by user setting or route change
			}
		}
	}, [ref]);

	const context = React.useMemo(
		() => ({
			playing,
			play,
			stop,
			pause,
			ref,
		}),
		[playing, play, stop, pause, ref]
	);
	return (
		<VideoContext.Provider value={context}>
			<MediaPlayerVisual data={data} />
		</VideoContext.Provider>
	);
};
