import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { FlexRow } from "@/components/grid/extensions";
import { ContentfulNextImage } from "@/components/image";
import { Typography } from "@/components/typography/typography";
import { VisualContent } from "@/components/visual/components";
import type { WidgetType } from "@/components/widget";
import { Widget } from "@/components/widget";
import { TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";
import type { KeyVisual, TextCollection } from "@/types/contentful-api";
import { AssetFit, AssetFocus } from "@/types/contentful-images";
import { staticHeaderImageLoader } from "@/utils/image";

const Background = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	width: 100%;
	${({ theme: { palette, mq } }) => css`
		background-color: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
		@media ${mq.l} {
			height: 780px;
		}
	`};
`;

const VisualText = styled.div<PropsWithTheme>`
	display: flex;
	z-index: 1;
	flex-direction: column;
	align-self: flex-end;
	justify-content: flex-end;
	padding: var(--spacing-xl) 0;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			padding: var(--spacing-xxxl) 0;
			align-self: flex-start;
		}
	`};
`;

const Gradient = styled.div<PropsWithTheme>`
	display: flex;
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	left: 0;
	height: 510px;
	overflow: hidden;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			height: 780px;
		}
	`};

	&::after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.6);
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
		background-repeat: no-repeat;
		background-position: 100% 100%;
		background-size: 100% 173px;
		${({ theme: { mq } }) => css`
			@media ${mq.l} {
				background-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
				background-size: 100% 250px;
			}
		`};
	}
`;

export const Layout2: React.FC<{ data: KeyVisual; textCollection: TextCollection }> = ({
	data,
	textCollection,
}) => {
	const { featuredImage, featuredImageMobile, headline, subtitle, widgetType } = data;
	return (
		<Background>
			{featuredImage && !featuredImageMobile && (
				<ContentfulNextImage
					priority
					loader={staticHeaderImageLoader}
					src={featuredImage.url}
					alt={featuredImage.description}
					quality={65}
					layout="fill"
					objectFit="cover"
					f={AssetFocus.faces}
					fit={AssetFit.thumb}
				/>
			)}
			{featuredImageMobile && featuredImageMobile && (
				<>
					<Hidden m l>
						<ContentfulNextImage
							priority
							loader={staticHeaderImageLoader}
							src={featuredImage.url}
							alt={featuredImage.description}
							quality={65}
							layout="fill"
							objectFit="cover"
							f={AssetFocus.faces}
							fit={AssetFit.thumb}
						/>
					</Hidden>
					<Hidden s>
						<ContentfulNextImage
							priority
							loader={staticHeaderImageLoader}
							src={featuredImageMobile.url}
							alt={featuredImageMobile.description}
							quality={50}
							layout="fill"
							objectFit="cover"
							f={AssetFocus.top}
							fit={AssetFit.fill}
						/>
					</Hidden>
				</>
			)}
			<Gradient />
			<Grid overflow>
				<Row>
					<Column flex l={7}>
						<VisualContent>
							<FlexRow>
								<Column flex l={6}>
									<VisualText>
										{headline && (
											<Typography
												tight
												component="h1"
												variant={TypographyVariant.headlineSerif2XL}
											>
												<BreakLines text={headline} />
											</Typography>
										)}
										{subtitle && (
											<Typography>
												<BreakLines text={subtitle} />
											</Typography>
										)}
									</VisualText>
								</Column>
							</FlexRow>
						</VisualContent>
					</Column>
					<Column l={5}>
						<Widget
							type={widgetType as WidgetType}
							data={data}
							textCollection={textCollection}
						/>
					</Column>
				</Row>
			</Grid>
		</Background>
	);
};
