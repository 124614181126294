import type { MutableRefObject } from "react";
import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { animated } from "@react-spring/web";

import { useVideoContext } from "@/hooks/video";
import type { PropsWithTheme } from "@/theme";

export interface ContentfulVideoProps {
	src: string;
	poster?: string;
	done?: boolean;
}

export const BackgroundVideo = styled.div<PropsWithTheme>`
	display: flex;
	position: absolute;
	z-index: 0;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;

	&::after {
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3));
	}
`;

export const StretchingVideo = styled.video`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

export const Wrapper = styled(animated.div)<PropsWithTheme>`
	display: flex;
	position: relative;
	width: 100%;
	height: calc(100vh - var(--header-height));
	min-height: 600px;
	margin: 0 auto;
	will-change: clip-path;
	${({ theme: { palette } }) => css`
		background-color: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
	`};
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			height: calc(100vh - var(--header-height) - var(--spacing-xxl));
			max-height: 900px;
		}
	`};
`;

export const ContentfulVideo = React.forwardRef<HTMLVideoElement, ContentfulVideoProps>(
	({ src, poster, done }, ref: MutableRefObject<HTMLVideoElement>) => {
		const { play } = useVideoContext();
		React.useEffect(() => {
			if (done) {
				play();
			}
		}, [done, play]);

		return <StretchingVideo ref={ref} muted loop playsInline src={src} poster={poster} />;
	}
);

export const getClipPathFromTime = (time: number) =>
	`polygon(0% ${50 * time}%, 0% ${100 - 50 * time}%, 100% ${100 - 50 * time}%, 100% ${
		50 * time
	}%)`;

export const VisualContent = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	flex: 1;
	flex-direction: column;
	min-height: 470px;
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			min-height: 510px;
		}
	`};
`;

export const VisualHeadline = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	z-index: 1;
	flex-direction: column;
	align-self: flex-end;
	padding: var(--spacing-xxl) 0 var(--spacing-xs);
	${({ theme: { mq } }) => css`
		@media ${mq.l} {
			padding: 0 0 var(--spacing-s);
		}
	`};
`;
