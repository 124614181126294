import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import type { WidgetType } from "@/components/widget";
import { Widget } from "@/components/widget";
import { TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";
import type { KeyVisual } from "@/types/contentful-api";

const Wrapper = styled.div<
	PropsWithTheme & { backgroundImage?: string; fixedHeight?: string; wave?: boolean }
>`
	display: flex;
	position: relative;
	height: 100vh;
	min-height: 600px;
	padding-top: var(--spacing-xxxl);
	background-image: url("/assets/images/wave_left_light.png");
	background-repeat: no-repeat;
	background-position: 100% 100%;
	background-size: 95%;
	${({ theme: { palette, mq } }) => css`
		background-color: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
		@media ${mq.m} {
			background-size: 700px;
		}
		@media ${mq.l} {
			height: calc(100vh - var(--header-height));
			padding-top: var(--spacing-l);
		}
	`};
`;

export const Layout6: React.VFC<{ data: Partial<KeyVisual> }> = ({
	data: { headline, subtitle, widgetType },
}) => {
	return (
		<Wrapper>
			<Grid overflow>
				<Row>
					<Column m={1} l={2} />
					<Column m={6} l={8}>
						<Typography
							centeredOnMobile
							tight
							component="h1"
							variant={TypographyVariant.headlineSerif2XL}
						>
							<BreakLines text={headline} />
						</Typography>
					</Column>
				</Row>
				<Row>
					<Column m={1} l={3} />
					<Column m={6}>
						{subtitle && (
							<>
								<Hidden m l>
									<Spacer spacing="l" />
								</Hidden>
								<Hidden s>
									<Spacer spacing="xs" />
								</Hidden>
								<Typography centeredOnMobile tight component="h2">
									<BreakLines text={subtitle} />
								</Typography>
							</>
						)}
					</Column>
				</Row>
				{widgetType && (
					<>
						<Spacer spacing="l" />
						<Row>
							<Column l={2} />
							<Column l={8}>
								<Widget type={widgetType as WidgetType} />
							</Column>
						</Row>
					</>
				)}
			</Grid>
		</Wrapper>
	);
};
