import React from "react";

import { Layout1 } from "@/components/visual/layout1";
import { Layout2 } from "@/components/visual/layout2";
import { Layout3 } from "@/components/visual/layout3";
import { Layout4 } from "@/components/visual/layout4";
import { Layout6 } from "@/components/visual/layout6";
import { Layout7 } from "@/components/visual/layout7";
import { MediaPlayer } from "@/components/visual/media-player";
import type { VisualProps } from "@/components/visual/types";
import { DisplayType } from "@/contentful/content-model/types";

export const Visual = ({
	data,
	large,
	textCollection,
	children,
	removeBottomSpacing,
}: VisualProps) => {
	if (data?.__typename === "MediaPlayer") {
		return <MediaPlayer data={data} />;
	}

	switch (data.displayType) {
		case DisplayType.layout1:
			/* With headline, boxed image, small widget  */
			return <Layout1 data={data} />;
		case DisplayType.layout2:
			/* With bg image, headline, subtitle, large widget (e.g. contact form) */
			return <Layout2 data={data} textCollection={textCollection} />;
		case DisplayType.layout4:
			// @ToDo: This "layout4" can be removed after content migration to MediaPlayer
			/* With bg video, headline, subtitle, small widget (e.g. Open Video Modal) */
			return <Layout4 data={data} />;
		case DisplayType.layout6:
			return <Layout6 data={data} />;
		case DisplayType.layout7:
			return <Layout7 data={data} />;
		case DisplayType.layout3:
		default:
			/* With bg image, headline, accepts children e.g. displaying property price info  */
			return (
				<Layout3 data={data} large={large} removeBottomSpacing={removeBottomSpacing}>
					{children}
				</Layout3>
			);
	}
};
