import React from "react";

import { useTheme } from "@emotion/react";

export const useViewport = () => {
	const theme = useTheme();

	const [l, setVpL] = React.useState(true);
	const [s, setVpS] = React.useState(true);
	const handleViewport = React.useCallback(() => {
		if (typeof window !== "undefined") {
			if (window.innerWidth < theme.breakpoints.l) {
				setVpL(false);
				setVpS(true);
			} else {
				setVpS(false);
				setVpL(true);
			}
		}
	}, [theme]);

	React.useEffect(() => {
		handleViewport();
		window.addEventListener("resize", handleViewport);
		return () => {
			window.removeEventListener("resize", handleViewport);
		};
	}, [theme, handleViewport]);

	return { s, l };
};
