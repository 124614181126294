import React from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Column, Grid, Hidden, Row } from "@/components/grid";
import { RelativeRow } from "@/components/grid/extensions";
import { ContentfulNextImage } from "@/components/image";
import { Typography } from "@/components/typography/typography";
import { BackgroundOverlay } from "@/components/visual/components";
import type { WidgetType } from "@/components/widget";
import { Widget } from "@/components/widget";
import { TypographyVariant } from "@/theme";
import type { PropsWithTheme } from "@/theme";
import type { KeyVisual } from "@/types/contentful-api";
import { AssetFit, AssetFocus } from "@/types/contentful-images";
import { staticHeaderImageLoader } from "@/utils/image";

const Wrapper = styled.div<PropsWithTheme>`
	display: flex;
	position: relative;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 360px;
	${({ theme: { palette, mq } }) => css`
		background-color: ${palette.freeze[1000]};
		color: ${palette.freeze[0]};
		@media ${mq.l} {
			height: 328px;
		}
	`};
`;

export const Layout7 = ({ data }: { data: KeyVisual }) => {
	const { featuredImage, featuredImageMobile, headline, widgetType } = data;

	return (
		<>
			<Wrapper>
				{featuredImage && !featuredImageMobile && (
					<ContentfulNextImage
						priority
						src={featuredImage.url}
						alt={featuredImage.description}
						f={AssetFocus.top}
						fit={AssetFit.fill}
						layout="fill"
						objectFit="cover"
					/>
				)}
				{featuredImageMobile && (
					<>
						<Hidden m l>
							<ContentfulNextImage
								priority
								src={featuredImage.url}
								alt={featuredImage.description}
								f={AssetFocus.top}
								fit={AssetFit.fill}
								layout="fill"
								objectFit="cover"
							/>
						</Hidden>
						<Hidden s>
							<ContentfulNextImage
								priority
								loader={staticHeaderImageLoader}
								src={featuredImageMobile.url}
								alt={featuredImageMobile.description}
								quality={50}
								layout="fill"
								objectFit="cover"
								f={AssetFocus.top}
								fit={AssetFit.fill}
							/>
						</Hidden>
					</>
				)}
				<BackgroundOverlay />
				<Grid overflow>
					<RelativeRow>
						<Column>
							<Typography
								bottom
								centeredOnMobile
								component="h1"
								variant={TypographyVariant.headlineSerifXL}
							>
								{headline}
							</Typography>
						</Column>
					</RelativeRow>
					{widgetType && (
						<Row>
							<Column l={2} m={1} />
							<Column l={8} m={6}>
								<Widget type={widgetType as WidgetType} />
							</Column>
						</Row>
					)}
				</Grid>
			</Wrapper>
		</>
	);
};
