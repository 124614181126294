import React from "react";

import Image from "next/image";

import { BreakLines } from "@/components/break-lines";
import { Column, Grid, Hidden, Row } from "@/components/grid";
import { FlexRow } from "@/components/grid/extensions";
import { ContentfulNextImage } from "@/components/image";
import { Spacer } from "@/components/layout/components";
import { Typography } from "@/components/typography/typography";
import type { WidgetType } from "@/components/widget";
import { Widget } from "@/components/widget";
import { TypographyVariant } from "@/theme";
import type { KeyVisual } from "@/types/contentful-api";
import { AssetFit, AssetFocus, AssetFormat } from "@/types/contentful-images";
import { staticHeaderImageLoader } from "@/utils/image";

import {
	Background,
	BackgroundOverlay,
	VisualContent,
	VisualHeadline,
	VisualImg,
	WaveImage,
} from "./components";

export const Layout1: React.FC<{ data: Partial<KeyVisual> }> = ({
	data: { featuredImage, featuredImageMobile, headline, subtitle, widgetType, widgetTitle },
}) => {
	return (
		<>
			<Background
				backgroundImage={featuredImage.url}
				hasFeaturedImageMobile={Boolean(featuredImageMobile)}
			>
				<Grid overflow>
					<Row>
						<Column flex l={6}>
							<VisualContent>
								<Hidden l>
									<WaveImage>
										<Image
											unoptimized
											priority
											src="/assets/images/wave_visual.png"
											alt=""
											layout="fill"
											objectFit="cover"
											aria-hidden="true"
										/>
									</WaveImage>
								</Hidden>
								<FlexRow>
									<Column flex l={6}>
										<VisualHeadline>
											<Typography
												tight
												component="h1"
												variant={TypographyVariant.headlineSerif2XL}
											>
												<BreakLines text={headline} />
											</Typography>
											{subtitle && <Typography>{subtitle}</Typography>}
										</VisualHeadline>
									</Column>
								</FlexRow>
								<Row>
									<Widget
										type={widgetType as WidgetType}
										data={{ widgetTitle }}
									/>
								</Row>
							</VisualContent>
						</Column>
						{featuredImageMobile && (
							<Hidden s>
								<ContentfulNextImage
									priority
									loader={staticHeaderImageLoader}
									src={featuredImageMobile.url}
									alt={featuredImageMobile.description}
									quality={50}
									layout="fill"
									objectFit="cover"
									f={AssetFocus.top}
									fit={AssetFit.fill}
								/>
								<BackgroundOverlay />
							</Hidden>
						)}
						<Column s={0} l={6}>
							<VisualImg>
								<Image
									priority
									unoptimized
									src={`${featuredImage.url}?q=75&w=740&h=740&fm=${AssetFormat.webp}&f=${AssetFocus.faces}&fit=${AssetFit.fill}`}
									alt={featuredImage.description}
									layout="fill"
									objectFit="cover"
								/>
							</VisualImg>
						</Column>
					</Row>
				</Grid>
			</Background>
			{Boolean(widgetType) && (
				<Hidden s m>
					<Spacer spacing="xxl" />
				</Hidden>
			)}
			<Hidden l>
				<Spacer spacing="xxl" />
			</Hidden>
		</>
	);
};
